<template>
  <div id="herramientas-proveedor">
    <div class="container-fluid" id="proveedor">
      
      <vx-card class="mb-6" >
        <div class="vx-row">
          <div class="vx-col w-full">
            <h1 class="rg-big-title extrabold rg-title">Mis <span class="orange">herramientas</span>.</h1>
          </div>
        </div>
      </vx-card>

      <vx-card class="mb-base">
        <div class="vx-row">

          <div class="vx-col lg:w-1/2 md:w-3/4 w-full">
            <h3 class="title-primary mt-3 mb-5">Información</h3>

            <div class="vx-row">
              <div class="vx-col w-full">
                <!-- Documentación de Solicitantes -->
                <action-button
                  id="reqs-cliente"
                  title="Documentación de Solicitantes"
                  actionLink="/informativos/REDGIRASOL®-Lista-Documentación-Solicitantes.pdf"
                  fileName="REDGIRASOL®-Lista-Documentación-Solicitantes.pdf"
                  :download="true"
                  :remoteFile="true"
                  icon="DownloadIcon"
                  color="primary"
                />
              </div>

               <div class="vx-col w-full">
                <!-- Presentación Ejecutiva de RedGirasol -->
                <action-button
                  id="caracteristicas-solar"
                  title="Presentación Ejecutiva de RedGirasol"
                  actionLink="/informativos/REDGIRASOL®-Credito-Solar-Instaladores.pdf"
                  fileName="REDGIRASOL®-Credito-Solar-Instaladores.pdf"
                  :download="true"
                  :remoteFile="true"
                  icon="DownloadIcon"
                  color="primary"
                />
               </div>
            </div>
          </div>

          <!-- SOLARES -->
          <div class="vx-col lg:w-1/2 md:w-3/4 w-full">
            <h3 class="title-primary mt-3 mb-5">Para tus clientes</h3>

            <div class="vx-row">
              <div class="vx-col w-full">
                <!-- PYMES -->
                <action-button
                  id="reqs-cliente"
                  title="Folleto para Crédito de PYMES"
                  actionLink="/informativos/REDGIRASOL®-Credito-Solar-PYMES.pdf"
                  fileName="REDGIRASOL®-Credito-Solar-PYMES.pdf"
                  :download="true"
                  :remoteFile="true"
                  icon="DownloadIcon"
                  color="primary"
                />
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col w-full">
                <!-- RedGirasol_NegociosSolares -->
                <action-button
                  id="reqs-cliente"
                  title="Folleto para Crédito de Negocios"
                  actionLink="/informativos/REDGIRASOL®-Credito-Solar-Negocios.pdf"
                  fileName="REDGIRASOL®-Credito-Solar-Negocios.pdf"
                  :download="true"
                  :remoteFile="true"
                  icon="DownloadIcon"
                  color="primary"
                />
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col w-full">
                <!-- Folleto para Crédito de Hogares -->
                <action-button
                  id="reqs-cliente"
                  title="Folleto para Crédito de Hogares"
                  actionLink="/informativos/REDGIRASOL®-Credito-Solar-Hogares.pdf"
                  fileName="REDGIRASOL®-Credito-Solar-Hogares.pdf"
                  :download="true"
                  :remoteFile="true"
                  icon="DownloadIcon"
                  color="primary"
                />
              </div>
            </div>
          </div>
        </div>
      </vx-card>

      <div v-if="isProgramSupplier">
        <program-tools :isSupplier="true"></program-tools>
      </div>

    </div>
  </div>
</template>

<script>
import ActionButton from '@components/supplier/ActionButton.vue'
import ProgramTools from '@views/programs/kof/Tools.vue'
export default {
  components: {
    ActionButton,
    ProgramTools
  },
  computed: {
    isProgramSupplier(){
      return this.SupplierOrAgentProgramAccess;
    },
  }
}
</script>
<style scoped>
  .rg-title {
      font-size: 3rem !important;
  }
</style>